window.select2 = require('select2');
require('../../node_modules/select2/dist/js/i18n/fr'); // french

window.initSelect2 = (el) => {
  el = el || document;
  const init = (sel, config) => {
    $(el).find(sel).each(function() {
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }
      $(this).parent().find('.select2-container').remove();
      $(this).select2(config);
    });
  };

  //
  init('select.select2-multiple', {
    multiple: true,
    closeOnSelect: false
  });
  
  //
  init('select.select2', {
    closeOnSelect: true
  });

  //
  init('select.select2-tags', {
    closeOnSelect: true,
    multiple: true,
    tags: true,
  });
};

window.initSelect2();
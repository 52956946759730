
$(() => {


  // datepicker
  $.datetimepicker.setLocale('fr');

  window.initDatepicker = () => {
    $('.datepicker').each(function() {
      const el = $(this);
      el.datetimepicker({
        lazyInit: true,
        format: 'd/m/Y',
        timepicker: false,
        dayOfWeekStart: 1,
        scrollInput: false,
        scrollMonth: false,
        scrollTime: false,
        weeks: !!el.data('weeks'),
        onSelectDate: (date) => {
          // update week
          const w = window.moment(date).format('W');
          w && el.closest('.input-group').find('.input-group-week').text('S' + w);
        }
      });
    });
  };
  window.initDatepicker();

  // timepicker am
  $('.timepicker').datetimepicker({
    lazyInit: true,
    format: 'H:i',
    step: 15,
    minTime: '07:00',
    maxTime: '21:00',
    timepicker: true,
    datepicker: false,
    scrollInput: false,
    scrollMonth: false,
    scrollTime: false,
    weeks: true
  });
  
  // timepicker am
  $('.timepicker-am').datetimepicker({
    lazyInit: true,
    format: 'H:i',
    step: 15,
    minTime: '07:00',
    maxTime: '14:15',
    timepicker: true,
    datepicker: false,
    scrollInput: false,
    scrollMonth: false,
    scrollTime: false,
    weeks: true
  });

  // timepicker pm
  $('.timepicker-pm').datetimepicker({
    lazyInit: true,
    format: 'H:i',
    step: 15,
    minTime: '12:00',
    maxTime: '20:15',
    timepicker: true,
    datepicker: false,
    scrollInput: false,
    scrollMonth: false,
    scrollTime: false,
    weeks: true
  });

  $(document).on('mouseup', '.xdsoft_datetimepicker .xdsoft_calendar th', function() {
    $(this).next().trigger('click');
  });

    
  $(document).on('click', '.datepicker-group', function() {
    $(this).find('input').trigger('focus');
  });
  
});